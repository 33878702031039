/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import AppLineForm from '@/components/Form/AppLineForm.vue'
import AppLineWhiteForm from '@/components/Form/AppLineWhiteForm.vue'
import AppFormTrust from '@/components/Form/AppFormTrust.vue'
import AppFormPopup from '@/components/Form/AppFormPopup.vue'
import AppFormCalc from '@/components/Form/AppFormCalc.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ButtonMenu from '@/assets/js/Button/Menu/ButtonMenu'
import Faq from '@/assets/js/Faq/Faq'
import TextShow from '@/assets/js/Text/Show/TextShow'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import HeaderFixed from '@/assets/js/Header/Fixed/HeaderFixed'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    Fancybox.bind('[data-fancybox-form]', {
        mainClass: 'fancybox---form'
    })

    if(document.getElementById('form--line') != null) {
        createApp({})
            .component('form-line', AppLineForm)
            .use(Maska)
            .use(form)
            .mount('#form--line')
    }

    if(document.getElementById('form--line-white') != null) {
        createApp({})
            .component('form-line-white', AppLineWhiteForm)
            .use(Maska)
            .use(form)
            .mount('#form--line-white')
    }

    if(document.getElementById('form--trust') != null) {
        createApp({})
            .component('form-trust', AppFormTrust)
            .use(Maska)
            .use(form)
            .mount('#form--trust')
    }

    if(document.getElementById('form--popup') != null) {
        createApp({})
            .component('form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#form--popup')
    }

    if(document.getElementById('form--calc') != null) {
        createApp({})
            .component('form-calc', AppFormCalc)
            .use(Maska)
            .use(form)
            .mount('#form--calc')
    }

    new SwiperSlider()
    new ButtonMenu()
    new Faq()
    new TextShow()
    new HeaderMobile()
    new MenuMobile()
    new HeaderFixed()
})
