/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderFixed {
    classHeader = 'page---header'
    classBody = 'body'
    classFixed = 'page---fixed'

    fixedBlocks (top, classHeader, classBody, classFixed) {
        if (top >= 100) {
            $(`.${classHeader}`).addClass(classFixed)
            $(`.${classBody}`).addClass(classFixed)
        } else {
            $(`.${classHeader}`).removeClass(classFixed)
            $(`.${classBody}`).removeClass(classFixed)
        }
    }

    constructor () {
        let self = this

        self.fixedBlocks($(window).scrollTop(), self.classHeader, self.classBody, self.classFixed)

        $(window).scroll(function () {
            let top = parseInt($(this).scrollTop())
            self.fixedBlocks(top, self.classHeader, self.classBody, self.classFixed)
        })
    }
}
