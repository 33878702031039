<template lang="pug">
.form-calc(:class="{'form-calc---success': sendForm}")
  .form-calc__block
    .form-calc__fields
      .form-calc__field
        select.select.select---white.select---width(v-model="fieldSelect1")
          option(v-for="(item, key) in fieldSelect1List" :value="item" v-html="item" :key="key")

      .form-calc__field
        select.select.select---white.select---width(v-model="fieldSelect2")
          option(v-for="(item, key) in fieldSelect2List" :value="item" v-html="item" :key="key")

      .form-calc__field
        select.select.select---white.select---width(v-model="fieldSelect3")
          option(v-for="(item, key) in fieldSelect3List" :value="item" v-html="item" :key="key")

      .form-calc__field
        .input-text.input-text---turquoise.input-text---width
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )

      .form-calc__field
        .button.button---red.button---width(@click="getSend()")
          .button__text(v-html="formButtonName")

    .form-calc__button
      .button.button---red.button---width-480(@click="getSend()")
        .button__text(v-html="formButtonName")

  .form-calc__success(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormCalc',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formFieldSelect1Name",
    "formFieldSelect1",

    "formFieldSelect2Name",
    "formFieldSelect2",

    "formFieldSelect3Name",
    "formFieldSelect3",

    "formButtonName",

    "formSuccessText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldSelect1: '',
      fieldSelect1List: [],
      fieldSelect2: '',
      fieldSelect2List: [],
      fieldSelect3: '',
      fieldSelect3List: [],
      fieldPhone: '',
      files: '',
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldSelect1Name,
              value: this.fieldSelect1
            },
            {
              title: this.formFieldSelect2Name,
              value: this.fieldSelect2
            },
            {
              title: this.formFieldSelect3Name,
              value: this.fieldSelect3
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  },

  created () {
    this.fieldSelect1List = this.formFieldSelect1.split('|')
    this.fieldSelect1 = this.fieldSelect1List[0]

    this.fieldSelect2List = this.formFieldSelect2.split('|')
    this.fieldSelect2 = this.fieldSelect2List[0]

    this.fieldSelect3List = this.formFieldSelect3.split('|')
    this.fieldSelect3 = this.fieldSelect3List[0]
  }
}
</script>
