/*global $*/
/*eslint no-undef: "error"*/
export default class Faq {
    classItem = 'faq--item'
    classOpen = 'faq---open'

    constructor () {
        let self = this

        $(`.${self.classItem}`).click(function () {
            $(this).toggleClass(self.classOpen)
        })
    }
}
