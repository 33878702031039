<template lang="pug">
.form-line(:class="{'form-line---success': sendForm}")
  .form-line__fields
    .form-line__field
      .input-text.input-text---turquoise.input-text---width
        input.input-text__input(
          type="tel"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form-line__field
      .button.button---red.button---width-800(@click="getSend()")
        .button__text(v-html="formButtonName")

  .form-line__success(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppLineForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formButtonName",

    "formSuccessText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldPhone: '',
      files: '',
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  }
}
</script>
