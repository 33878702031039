/*global $*/
/*eslint no-undef: "error"*/
export default class TextShow {
    classItem = 'text-show--item'
    classButton = 'text-show--button'
    classText = 'text-show--text'

    classOpen = 'text-show---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')

            if ($(this).closest(`.${self.classItem}`).hasClass(self.classOpen)) {
                $(this).closest(`.${self.classItem}`).removeClass(self.classOpen)
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textTo)
            } else {
                $(this).closest(`.${self.classItem}`).addClass(self.classOpen)
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textDo)
            }
        })
    }
}
