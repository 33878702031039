<template lang="pug">
.form-popup(:class="{'form-popup---success': sendForm}")
  .form-popup__wrapper(:style="{backgroundImage: 'url(' + formBackground + ')'}")
    .form-popup__title(v-html="formTitle")

    .form-popup__block
      .form-popup__fields
        .form-popup__field
          .form-popup__field
            label.input-text.input-text---white.input-text---width
              span.input-text__label(v-html="formFieldNameLabel")
              input.input-text__input(
                type="text"
                v-model="fieldName"
                :placeholder="formFieldNamePlaceholder"
              )

          .form-popup__field
            label.input-text.input-text---white.input-text---width
              span.input-text__label(v-html="formFieldPhoneLabel")
              input.input-text__input(
                type="tel"
                v-model="fieldPhone"
                :placeholder="formFieldPhonePlaceholder"
                v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
              )

      .form-popup__consent
        label.consent.consent---white.consent---400
          input.consent__input(type="checkbox" value="consent" checked="checked" v-model="consent")
          span.consent__label(v-html="formConsent")

      .form-popup__button
        .button.button---red.button---width(@click="getSend()")
          .button__text(v-html="formButtonName")

    .form-popup__success(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPopup',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldName",
    "formFieldNameLabel",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhoneLabel",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formBackground",
    "formTitle",
    "formButtonName",

    "formSuccessText",

    "formConsent",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
